import React, { Suspense, useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Layout from "./layout";
import LayoutSetting from "./layout/LayoutSetting";
import PageLoading from "./components/shared/PageLoading";
import useSocketListener from "./hooks/useSocketListener";
import { useDispatch } from "react-redux";
import { PAGE_TITLE } from "./utils/constants/common";
import { getListGroupChannels, loadListChannel } from "./api/channel";
import { setChannels, setGroupChannels } from "./reducers/channel";
import { getListTag } from "./api/conversationScript";
import { setTags } from "./reducers/conversationScriptSlice";
import ConversationTagPage from "./pages/ConversationTagPage";
import SoundNotificationSetting from "./pages/SoundNotificationSetting";
import LayoutCampaign from "./layout/Campaign/LayoutCampaign";
import Campaign from "./pages/Campaign";
import moment from "moment-timezone";
import "moment/locale/en-gb";
import { getProject } from "./api/project";
import ChannelViewTypePage from "./pages/ChannelViewTypePage";
import { getUserInfor } from "./helper/common";
import MobileLayout from "./layout/mobile/MobileLayout";
import MobileCampaign from "./pages/mobile/campaign";
import MobileSetting from "./pages/mobile/setting";
import { useIsMobile } from "./utils/hooks/useIsMobile";

const Home = React.lazy(() => import("./pages/Home"));
const ListConversationView = React.lazy(
  () => import("./pages/mobile/ListConversationView")
);
const ConversationScriptPage = React.lazy(
  () => import("./pages/ConversationScriptPage")
);

function App() {
  const dispatch = useDispatch();
  useSocketListener(dispatch);
  const navigate = useNavigate();
  const location = useLocation();
  document.title = PAGE_TITLE;

  const userInfor = getUserInfor();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      if (location.pathname.startsWith("/campaign/")) {
        navigate("/campaign");
      } else if (location.pathname.startsWith("/setting/")) {
        navigate("/setting");
      }
    } else {
      if (location.pathname === "/campaign") {
        navigate("/campaign/bot-auto");
      } else if (location.pathname === "/setting") {
        navigate("/setting/conversation-script");
      }
    }
  }, [isMobile, location.pathname, navigate]);

  const fetchListChannels = async () => {
    loadListChannel(userInfor.last_project_active, {
      status: 1,
    })
      .then((response) => {
        if (response.status) {
          dispatch(setChannels(response?.data?.channel));
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const fetchListGroupChannels = async () => {
    getListGroupChannels(userInfor.last_project_active)
      .then((response) => {
        if (response.status) {
          dispatch(setGroupChannels(response?.data));
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const fetchListTags = async () => {
    const data = {
      params: {
        project_id: userInfor.last_project_active,
      },
    };
    getListTag(data)
      .then((response) => {
        if (response.status) {
          dispatch(setTags(response?.data));
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const fetchTimezone = async () => {
    getProject(userInfor.last_project_active)
      .then((response) => {
        let timezone = "Asia/Ho_Chi_Minh";
        if (response.status && response.time_zone) {
          timezone = response.time_zone;
        }
        moment.tz.setDefault(timezone);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchTimezone();
    fetchListChannels();
    fetchListGroupChannels();
    fetchListTags();
  }, []);

  return (
    <div className={"App"}>
      <Routes>
        <Route element={isMobile ? <MobileLayout /> : <Layout />}>
          <Route
            path={"/"}
            element={
              <Suspense fallback={<PageLoading />}>
                {isMobile ? <ListConversationView /> : <Home />}
              </Suspense>
            }
          />

          <Route
            path={"setting"}
            element={isMobile ? <MobileSetting /> : <LayoutSetting />}
          >
            {!isMobile && (
              <>
                <Route
                  path={"conversation-script"}
                  element={
                    <Suspense fallback={<PageLoading />}>
                      <ConversationScriptPage />
                    </Suspense>
                  }
                />
                <Route
                  path={"conversation-tag"}
                  element={
                    <Suspense fallback={<PageLoading />}>
                      <ConversationTagPage />
                    </Suspense>
                  }
                />
                <Route
                  path={"sound-notification"}
                  element={
                    <Suspense fallback={<PageLoading />}>
                      <SoundNotificationSetting />
                    </Suspense>
                  }
                />
                <Route
                  path={"channel-view-type"}
                  element={
                    <Suspense fallback={<PageLoading />}>
                      <ChannelViewTypePage />
                    </Suspense>
                  }
                />
              </>
            )}
          </Route>

          <Route
            path={"campaign"}
            element={isMobile ? <MobileCampaign /> : <LayoutCampaign />}
          >
            {!isMobile && (
              <>
                <Route
                  path={"bot-auto"}
                  element={
                    <Suspense fallback={<PageLoading />}>
                      <Campaign />
                    </Suspense>
                  }
                />
              </>
            )}
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
