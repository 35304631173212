import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import {getUserInfor} from "../helper/common";
import {EVENT_NAME_SOCKET, EVENT_REMIND_CONVERSATION_SOCKET} from "../utils/constants/socket";
import {ISocketMessage, ISocketRemind} from "../dto/socket";
import {Dispatch} from "@reduxjs/toolkit";
import {setCommentSocket, setChatSocket, setIsNewMessage, setCurrentRemind} from "../reducers/conversationSlice";
import sound from '../assets/audio/noti-sound.wav'
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { PAGE_TITLE } from '../utils/constants/common';
import { setNotifySetting } from '../reducers/conversationScriptSlice';
import { getSettingNotifycation } from '../api/conversationScript';

const useSocketListener = (dispatch : Dispatch) => {
  const socketRef = useRef<any>();
  const userInfor = getUserInfor();
  const eventListenName = EVENT_NAME_SOCKET
    .replace('{projectId}', userInfor?.last_project_active)
    .replace('{userId}', userInfor?.id);
  const isNewMessage = useSelector((state: RootState)=> state.conversation.isNewMessage)
  const [data, setData]= useState<ISocketMessage | null>(null)
  const handleSetTitleNewMessage = (data: ISocketMessage | null) => {
    if(isNewMessage && data && document.title === PAGE_TITLE) {
      document.title = data.relate_conversation_item.sender.name + " đã gửi cho bạn 1 tin nhắn mới"

    } else if (document.title !== PAGE_TITLE){
      document.title = PAGE_TITLE
    }
  }

  let setting  : any= {}
  const eventListenCurrentRemind = EVENT_REMIND_CONVERSATION_SOCKET.replace(
    "{projectId}",
    userInfor?.last_project_active
  ).replace("{userId}", userInfor?.id);
  

  useEffect(()=>{
    let timer: NodeJS.Timer | null = null 
    
    if(isNewMessage) {
      timer = setInterval(()=>{
        handleSetTitleNewMessage(data)
      }, 2000)
    }
    
    return (()=>{
      if(timer){
        clearInterval(timer)
      }
    })
  }, [isNewMessage, data])
 
  const fetchSettingNotify = async () => {
    const data={
      params:{
        project_id: userInfor.last_project_active
      }
    }
    let listSetting = {
      is_enable_sound_remind:  0,
      is_enable_sound_message: 0,
      _id : "", 
      isload : true,
    }
    dispatch(setNotifySetting(listSetting))
    getSettingNotifycation(data)
      .then((response) => {
        if (response.status) {
           listSetting = {
            is_enable_sound_remind:  response.data.is_enable_sound_remind,
            is_enable_sound_message: response.data.is_enable_sound_message,
            isload : false,
            _id : response.data._id
          }
            dispatch(setNotifySetting(listSetting))
            setting = listSetting;
             // @ts-ignore
       socketRef.current?.on(eventListenName, handleIncomingMessage);
        } else {
          listSetting = {
            ...listSetting , 
            isload : false
          }
          dispatch(setNotifySetting(listSetting))
        }
      })
      .catch((error: any) => {
        listSetting = {
          ...listSetting , 
          isload : false
        }
        dispatch(setNotifySetting(listSetting))
        console.error(error);
      });
  }

  
  const handleIncomingMessage = (data: ISocketMessage) => {
      console.log({socket: data})
      if(!data ||
        typeof data.action === 'undefined' ||
        typeof data.item === 'undefined' ||
        typeof data.conversation === 'undefined' ||
        typeof data.relate_conversation_item === 'undefined'
      ) {
        return;
      }
        
      if (data.conversation.channel_infor._id !== data.relate_conversation_item.sender.id) {
        dispatch(setIsNewMessage({isNewMessage: true})) 
        if (setting && setting?.is_enable_sound_message == 1) {
        const audio = new Audio(sound) 
        audio.play()
        }
      
      }

      setData(data)
      switch (data.item) {
        case 'chat':
          handleChat({payload: data, dispatch})
          break;
        case 'comment':
          handleComment({payload: data, dispatch})
          break;
        default:
          return;
      }
    };
  useEffect(() => {
    // Connect to the Socket.IO server
    // @ts-ignore
    socketRef.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
      transports: ["websocket"],
      withCredentials: true,
    });
    fetchSettingNotify();
    
    // Listen for events on the specified channel
    const handleRemind = (data: ISocketRemind) => {
      dispatch(setCurrentRemind(data));
      if (setting && setting?.is_enable_sound_remind == 1) {
        const audio = new Audio(sound) 
        audio.play()
        }
    }
    socketRef.current?.on(
      eventListenCurrentRemind,
      handleRemind
    );
   

    return () => {
      // Clean up the socket connection when component unmounts
      if (socketRef.current) {
        // @ts-ignore
        socketRef.current?.off(channelName, handleIncomingMessage);
        // @ts-ignore
        socketRef.current?.disconnect();
      }
    };
    // eslint-disable-next-line
  }, []);

  return null; // Custom hooks typically return some value or null
};

export function handleChat({payload, dispatch} : {
  payload: ISocketMessage
  dispatch : Dispatch
}) {
  dispatch(setChatSocket(payload))
  return null;
}

export function handleComment({payload, dispatch} : {
  payload: ISocketMessage
  dispatch : Dispatch
}) {
  dispatch(setCommentSocket(payload))
  return null;
}


export default useSocketListener;



