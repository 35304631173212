import { useState } from "react";
import CampaignBot from "../../../components/campaign/campaign-bot";

export default function MobileCampaign() {
  const [selectedTab, setSelectedTab] = useState("bot-auto");

  const campaignTabs = [{ key: "bot-auto", label: "So9 Bot" }];

  const selectedIndex = campaignTabs.findIndex((tab) => tab.key === selectedTab);

  return (
    <div className="mt-[55px] bg-gray-100">
      <div className="relative bg-header flex items-center justify-between w-full pt-2">
        {campaignTabs.map((tab) => (
          <div
            key={tab.key}
            className={`cursor-pointer text-[12px] flex-1 py-2 mx-2 flex justify-center items-center font-bold transition-colors duration-300 ${
              selectedTab === tab.key ? "text-white" : "text-[#999]"
            }`}
            onClick={() => setSelectedTab(tab.key)}
          >
            {tab.label}
          </div>
        ))}

        <div className="absolute bottom-0 left-0 w-full flex justify-center">
          <div
            className="h-[3px] bg-white transition-transform duration-300 ease-in-out"
            style={{
              width: `${100 / campaignTabs.length}%`,
              transform: `translateX(${selectedIndex * 100 - 100}%)`,
            }}
          ></div>
        </div>
      </div>

      <div className="h-[calc(100vh-95px)] overflow-y-auto pt-[55px]">
        <CampaignBot />
      </div>
    </div>
  );
}
