import { useEffect, useState } from "react";
import ConversationScript from "../../../components/conversation-script";
import ConversationTag from "../../../components/conversation-tag";
import NotificationSetting from "../../../components/nofitication-setting";
import ChannelViewTypePage from "../../ChannelViewTypePage";

export default function MobileSetting() {
  const [selectedTab, setSelectedTab] = useState("conversation-script");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedTab]);

  const settingTabs = [
    { key: "conversation-script", label: "Quick Reply" },
    { key: "conversation-tag", label: "Tag" },
    { key: "sound-notification", label: "Notification" },
    { key: "channel-view-type", label: "Channel View" },
  ];

  const selectedIndex = settingTabs.findIndex((tab) => tab.key === selectedTab);

  return (
    <div className="mt-[55px]">
      <div className="relative bg-header flex items-center justify-between w-full pt-2">
        {settingTabs.map((tab) => (
          <div
            key={tab.key}
            className={`cursor-pointer text-[12px] flex-1 py-2 mx-1 flex justify-center items-center font-bold transition-colors duration-300 ${
              selectedTab === tab.key ? "text-white" : "text-[#999]"
            }`}
            onClick={() => setSelectedTab(tab.key)}
          >
            {tab.label}
          </div>
        ))}

        <div className="absolute bottom-0 left-0 w-full flex justify-center">
          <div
            className="h-[3px] bg-white transition-transform duration-300 ease-in-out"
            style={{
              width: `${100 / settingTabs.length}%`,
              transform: `translateX(${selectedIndex * 100 - 150}%)`,
            }}
          ></div>
        </div>
      </div>

      <div
        className="transition-opacity duration-300 ease-in-out"
        style={{
          height: "calc(100vh - 95px)",
          overflowY: "auto",
          opacity: 1, 
        }}
      >
        {selectedTab === "conversation-script" && <ConversationScript />}
        {selectedTab === "conversation-tag" && <ConversationTag />}
        {selectedTab === "sound-notification" && <NotificationSetting />}
        {selectedTab === "channel-view-type" && <ChannelViewTypePage />}
      </div>
    </div>
  );
}
