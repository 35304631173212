import { Typography } from "antd";
import Topic from "./topic";
import { useEffect, useState } from "react";
import {
  loadListReplySample,
  loadListTopic,
} from "../../api/conversationScript";
import { Note } from "./note";
import ReplySample from "./replySample";
import { useDispatch } from "react-redux";
import {
  setReplySamples,
  setReplyTopics,
} from "../../reducers/conversationScriptSlice";
import { useTranslation } from "react-i18next";
import { getUserInfor } from "../../helper/common";
import { useIsMobile } from "../../utils/hooks/useIsMobile";

export default function ConversationScript() {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [loadingTopic, setLoadingTopic] = useState(true);
  const [loadingSample, setLoadingSample] = useState(true);

  const userInfor = getUserInfor();

  const getListTopic = async () => {
    try {
      const listTopic = await loadListTopic(
        userInfor?.last_project_active as string
      );
      dispatch(setReplyTopics(listTopic));
      setLoadingTopic(false);
    } catch (e) {
      dispatch(setReplyTopics(undefined));
      setLoadingTopic(false);
    }
  };

  const getListRepSample = async () => {
    try {
      const listReply = await loadListReplySample(
        userInfor?.last_project_active as string
      );
      dispatch(setReplySamples(listReply));
      setLoadingSample(false);
    } catch (e) {
      dispatch(setReplySamples(undefined));
      setLoadingSample(false);
    }
  };

  useEffect(() => {
    getListTopic();
    getListRepSample();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${
        isMobile ? "bg-gray-100 py-[55px] min-h-[100vh]" : "m-auto 2xl:max-w-[1200px] max-w-[1100px]"
      }`}
    >
      {!isMobile && (
        <Typography className={`text-2xl`}>
          {t("script-setting.header")}
        </Typography>
      )}
      <div className={`flex gap-4 ${isMobile ? "flex-col px-4" : "mt-6"}`}>
        <div className={`${isMobile ? "w-100" : "w-[356px]"}`}>
          <Topic loadingTopic={loadingTopic} />
          <Note />
        </div>
        <div className={`flex-1`}>
          <ReplySample loadingSample={loadingSample} />
        </div>
      </div>
    </div>
  );
}
