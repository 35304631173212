import ItemContent from "../../shared/ItemContent";
import { IconPlus } from "../../../assets/svg/ConversationScript/IconConersationScript";
import IconTag from "../../../components/icons/iconTag.svg";
import { message, Spin } from "antd";
import ItemTag from "./ItemTopic";
import { lazy, Suspense, useState } from "react";
import { deleteTag } from "../../../api/conversationScript";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useTranslation } from "react-i18next";
import { setTags } from "../../../reducers/conversationScriptSlice";
import { getUserInfor } from "../../../helper/common";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

export default function Tags({ loadingTopic }: { loadingTopic: boolean }) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [currentTopic, setCurrentTopic] = useState({});
  const userInfor = getUserInfor();
  const tags = useSelector((state: RootState) => state.conversationScript.tags);

  const generateListTag = () => {
    if (loadingTopic) {
      return (
        <div className={`flex items-center h-full justify-center`}>
          <Spin size="large"></Spin>
        </div>
      );
    } else {
      if (tags === undefined) {
        return <>{t("tag.none-tag")}</>;
      } else if (tags.length === 0) {
        return (
          <div className={`flex justify-center text-sm font-semibold`}>
            {t("tag.empty-tag")}
          </div>
        );
      } else {
        return (
          <div className={`flex flex-col max-h-[700px] gap-2 overflow-auto`}>
            {tags.map((item, key) => {
              return (
                <ItemTag
                  item={item}
                  key={key}
                  handleDelTag={() => {
                    handleDelTag(item._id);
                  }}
                  handleEditTag={() => handleEditTopic(item)}
                />
              );
            })}
          </div>
        );
      }
    }
  };

  const handleEditTopic = (item: any) => {
    if (item) {
      setCurrentTopic(item);
    }
    setOpenModalEdit(true);
  };

  const handleDelTag = async (id: string) => {
    try {
      const data = {
        params: {
          project_id: userInfor.last_project_active,
        },
      };
      const delRes = await deleteTag(id, data);
      if (delRes.status) {
        let newListTag = [...(tags || [])];
        if (tags && tags.length > 0) {
          newListTag = tags.filter((tag) => tag._id !== id);
        }
        console.log(newListTag);

        dispatch(setTags(newListTag));
        message.success(t("alertmess.succes.del-tag"));
      } else {
        message.error(t("alertmess.error.del-tag"));
      }
    } catch (error) {
      message.error(t("alertmess.error.del-tag"));
    }
  };
  return (
    <div className={`${isMobile ? "border-[1px] rounded-lg" : ""}`}>
      <ItemContent
        childContent={generateListTag()}
        iconTitle={<img src={IconTag} />}
        iconExtra={
          <div
            className={`cursor-pointer`}
            onClick={() => setOpenModalAdd(true)}
          >
            <IconPlus />
          </div>
        }
        title={t("tag.tag_list")}
      />
      <Suspense>
        <ModalAddNewTag
          open={openModalAdd}
          handleClose={() => setOpenModalAdd(false)}
          type="add"
          currentTag={currentTopic}
        />

        <ModalAddNewTag
          open={openModalEdit}
          handleClose={() => setOpenModalEdit(false)}
          currentTag={currentTopic}
          type="edit"
        />
      </Suspense>
    </div>
  );
}

const ModalAddNewTag = lazy(() => import("./ModalAddNewTag"));
