import LeftMenu from "./LeftMenu";
import {Outlet} from "react-router-dom";

export default function LayoutSetting() {
  return (
    <div className={`flex bg-gray-200`}>
      <LeftMenu/>
      <div className={`max-w-[90vw] lg:max-w-[76vw] xl:max-w-[90vw] flex-1 bg-gray-200 p-4 pb-0 text-left`}>
        <Outlet/>
      </div>
    </div>
  )
}