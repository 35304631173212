import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getUserInfor } from "../../helper/common";
import { getConversations } from "../../api/conversation";
import { IConversationItem, IConversationItemLoaded } from "../../dto";
import {
  resetConversationByFilter,
  setConversationList,
} from "../../reducers/conversationSlice";
import { message } from "antd";
import { useEffect, useState } from "react";
import Avatar from "../../components/shared/Avatar";
import "remixicon/fonts/remixicon.css";
import {
  loadListReplySample,
  loadListTopic,
} from "../../api/conversationScript";
import {
  setReplySamples,
  setReplyTopics,
} from "../../reducers/conversationScriptSlice";

export default function DetailConversationHeader({
  toggleTagSide,
  toggleNoteSide,
  toggleMenuSide,
  toggleCloseAll,
}: any) {
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState<IConversationItemLoaded>();
  const {
    filters,
    searchText,
    conversationListLoaded,
    activeConversationId,
  } = useSelector((state: RootState) => state.conversation);
  const userInfor = getUserInfor();

  const { replySamples, replyTopics } = useSelector(
    (state: RootState) => state.conversationScript
  );

  const getListRepSample = async () => {
    if (!replySamples) {
      try {
        const listReply = await loadListReplySample(
          getUserInfor()?.last_project_active as string
        );
        dispatch(setReplySamples(listReply));
      } catch (e) {
        dispatch(setReplySamples(undefined));
      }
    }
  };

  const getListTopic = async () => {
    if (!replyTopics) {
      try {
        const listTopic = await loadListTopic(
          getUserInfor()?.last_project_active as string
        );
        dispatch(setReplyTopics(listTopic));
      } catch (e) {
        dispatch(setReplyTopics(undefined));
      }
    }
  };

  useEffect(() => {
    getListRepSample();
    getListTopic();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkItemLoaded = conversationListLoaded.find(
      (item) => item.conversationId === activeConversationId
    );
    if (checkItemLoaded) {
      setActiveItem(checkItemLoaded);
    }
  }, [activeConversationId, conversationListLoaded]);

  const fetchConversation = async () => {
    try {
      const conversations: IConversationItem[] = await getConversations({
        project_id: userInfor?.last_project_active || "",
        page: 1,
        filter: filters,
        ...(searchText && { search: searchText }),
      });
      dispatch(setConversationList(conversations));
    } catch (e) {
      console.error(e);
      message.error(
        "Đã có lỗi xảy ra khi tải các đoạn hội thoại. Vui lòng thử lại sau."
      );
    }
  };

  return (
    <>
      <div className="bg-header h-[55px] flex items-center justify-between fixed top-0 w-full px-4 text-white z-10000">
        <div className="flex gap-2 h-[55px] items-center">
          <div
            className="flex h-[55px] items-center"
            onClick={() => {
              toggleCloseAll();
              dispatch(resetConversationByFilter());
              fetchConversation();
            }}
          >
            <i className="text-[18px] ri-arrow-left-s-line"></i>
          </div>
          {activeItem && (
            <>
              <Avatar size={40} url={activeItem?.customerInfor?.avatar} />
              <div className={`font-bold text-white text-sm`}>
                {activeItem?.customerInfor?.name}
              </div>
            </>
          )}
        </div>
        <div className="flex gap-4 h-[55px] items-center">
          <div onClick={toggleTagSide}>
            <i className="text-[18px] ri-price-tag-3-fill"></i>
          </div>
          <div onClick={toggleNoteSide}>
            <i className="text-[18px] ri-sticky-note-fill"></i>
          </div>
          <div onClick={toggleMenuSide}>
            <i className="text-[18px] ri-more-2-line"></i>
          </div>
        </div>
      </div>
    </>
  );
}
