import { useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";

export default function Layout() {
  const [isScrolled, setIsScrolled] = useState(false);

  const outletContext = {
    setIsScrolled,
  };

  return (
    <>
      <Header />
      <div className={`mt-[55px]`}>
        <Outlet context={outletContext} />
      </div>
    </>
  );
}
