import { useNavigate } from "react-router-dom";

export default function DrawerHamburgerBar({ isOpenBar, toggleBar }: any) {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`fixed top-[55px] right-0 h-full w-3/4 bg-white shadow-lg transform ${
          isOpenBar ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out z-[10001]`}
      >
        <div className="p-4 flex justify-start items-start">
          <h2 className="text-[16px] font-[600] text-gray-400">Menu</h2>
        </div>
        <div className="flex flex-col">
          <div className="h-[1px] bg-gray-200 w-100"></div>

          <div className="text-start flex items-center gap-2 p-4 text-[12px] font-[400] active:bg-gray-300"
          onClick={() => {
            navigate("/campaign");
            toggleBar();
          }}
          >
            <i className="ri-bar-chart-2-fill text-[18px]"></i>
            <span className="ml-1">Campaign</span>
          </div>

          <div className="h-[1px] bg-gray-200 w-100"></div>

          <div className="text-start flex items-center gap-2 p-4 text-[12px] font-[400] active:bg-gray-300"
           onClick={() => {
            navigate("/setting");
            toggleBar();
          }}
          >
            <i className="ri-settings-3-fill text-[18px]"></i>
            <span className="ml-1">Setting</span>
          </div>

          <div className="h-[1px] bg-gray-200 w-100"></div>
        </div>
      </div>

      {isOpenBar && (
        <div
          className="fixed top-[55px] inset-0 bg-black bg-opacity-50 z-[10000]"
          onClick={toggleBar}
        ></div>
      )}
    </>
  );
}
