import { Radio, Select, Form, FormInstance, Spin, Input } from "antd";
import { useEffect, useState } from "react";
import { ItemHeader } from "./ItemHeader";
import { getListGroupChannels, loadListChannel } from "../../../api/channel";
import { getPlatformLabel, getUserInfor } from "../../../helper/common";
import {
  ICreateAutomaticScript,
  IUpdateAutomaticScrip,
} from "../../../dto/automatic-script/request/request";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

enum CHANNEL_TYPE {
  CHANNEL = 1,
  GROUP = 2,
}

export enum TAB_TYPE {
  CHANNEL = 1,
  POST = 2,
}

export const ItemApplicableObject = ({
  itemSelectedToEdit,
  setData,
  form,
}: {
  itemSelectedToEdit?: ICreateAutomaticScript | IUpdateAutomaticScrip | any;
  setData: any;
  form: FormInstance;
}) => {
  const userInfo = getUserInfor();
  const isMobile = useIsMobile();
  const [channelType, setChannelType] = useState<CHANNEL_TYPE>(
    itemSelectedToEdit?.channel?.type || CHANNEL_TYPE.CHANNEL
  );
  const [selectedTab, setSelectedTabe] = useState<TAB_TYPE>(TAB_TYPE.CHANNEL);

  const conditionTypeChannel = () => {
    return itemSelectedToEdit?.channel?.type === CHANNEL_TYPE.CHANNEL;
  };

  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>(
    conditionTypeChannel() ? itemSelectedToEdit?.channel?.ids : []
  );

  const [selectedGroupItems, setSelectedGroupItems] = useState<string[]>(
    !conditionTypeChannel() ? itemSelectedToEdit?.channel?.ids : []
  );
  const [options, setOptions] = useState<any[]>([]);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);

  const [channels, setChannels] = useState<any[]>([]);
  const [groupChannels, setGroupChannels] = useState<any[]>([]);

  const [selectedPostIds, setSelectedPostIds] = useState<string[]>(
    itemSelectedToEdit?.post?.ids || []
  );

  const onChangeChannel = (value: any) => {
    setChannelType(value);
    setData((pre: any) => ({
      ...pre,
      channel: {
        type: value,
        ids:
          value === CHANNEL_TYPE.CHANNEL ? selectedItems : selectedGroupItems,
      },
      channelIds:
        value === CHANNEL_TYPE.CHANNEL ? selectedItems : selectedGroupItems,
      post: {
        type: 3,
        ids: [],
      },
    }));

    form.setFieldsValue({
      channel_ids:
        value === CHANNEL_TYPE.CHANNEL ? selectedItems : selectedGroupItems,
    });
  };

  const onChangeChannelIds = (e: any) => {
    setSelectedPostIds([]);
    conditionTypeChannel() ? setSelectedItems(e) : setSelectedGroupItems(e);

    setData((pre: any) => ({
      ...pre,
      post: {
        type: 3,
        ids: [],
      },
      channel: {
        type: CHANNEL_TYPE.CHANNEL,
        ids: conditionTypeChannel() ? selectedItems : selectedGroupItems,
      },
      channelIds: conditionTypeChannel() ? selectedItems : selectedGroupItems,
    }));
  };

  const onChangePostIds = (e: any) => {
    const value = e.target.value;
    const postIds = value.split(",").map((id: string) => id.trim());

    setSelectedPostIds(postIds);

    setData((pre: any) => ({
      ...pre,
      post: {
        type: 3,
        ids: postIds,
      },
      channel: {
        type: CHANNEL_TYPE.CHANNEL,
        ids: [],
      },
      channelIds: [],
    }));

    setSelectedItems([]);

    form.setFieldsValue({
      post_ids: postIds,
    });
  };

  useEffect(() => {
    const fetchChannels = async () => {
      setLoadingOptions(true);
      try {
        const [channelResponse, groupChannelResponse] = await Promise.all([
          loadListChannel(userInfo.last_project_active, {
            status: 1,
            is_error: 0,
          }),
          getListGroupChannels(userInfo.last_project_active),
        ]);

        const channelData = channelResponse.data.channel;
        const groupChannelData = groupChannelResponse.data;

        const channelInfoSelected =
          itemSelectedToEdit?.channel_info?.full_info || [];
        const mergedChannels = channelData.concat(
          channelInfoSelected.filter(
            (item2: any) =>
              !channelData.some((item1: any) => item1._id === item2._id)
          )
        );
        const mergedGroupChannels = groupChannelData.concat(
          channelInfoSelected.filter(
            (item2: any) =>
              !groupChannelData.some((item1: any) => item1._id === item2._id)
          )
        );

        setChannels(mergedChannels);
        setGroupChannels(mergedGroupChannels);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingOptions(false);
      }
    };

    fetchChannels();
  }, []);

  useEffect(() => {
    if (channelType === CHANNEL_TYPE.CHANNEL) {
      const grouped = channels.reduce(
        (groups: Record<string, any[]>, item: any) => {
          const platform = getPlatformLabel(item.platform);
          if (!groups[platform]) {
            groups[platform] = [];
          }
          groups[platform].push({
            value: item._id,
            label: item.name,
          });
          return groups;
        },
        {}
      );

      const groupedOptions = Object.entries(grouped).map(
        ([platform, opts]) => ({
          label: platform,
          options: opts,
        })
      );

      setOptions(groupedOptions);
    } else {
      setOptions(
        groupChannels.map((item: any) => ({
          value: item._id,
          label: item.name,
        }))
      );
    }
  }, [channelType, channels, groupChannels]);

  useEffect(() => {
    setData((pre: any) => ({
      ...pre,
      channel: {
        type: channelType,
        ids: conditionTypeChannel() ? selectedItems : selectedGroupItems,
      },
      channelIds: conditionTypeChannel() ? selectedItems : selectedGroupItems,
    }));

    form.setFieldsValue({
      channel_ids: conditionTypeChannel() ? selectedItems : selectedGroupItems,
    });
  }, [channelType, selectedItems, selectedGroupItems]);

  return (
    <div className={`${isMobile ? "border-[1px] rounded-lg" : ""}`}>
      <div
        className={`flex ${
          isMobile
            ? "flex-col rounded-lg items-start"
            : "rounded-t-lg items-center"
        } p-4 bg-gray-100 gap-2`}
      >
        <div className="flex items-center gap-2 ">
          <ItemHeader title={t("campaign.applicable_object")} />
        </div>
        <div className={`${isMobile ? "" : "pl-8"} items-center`}>
          <Radio.Group
            onChange={(e) => setSelectedTabe(e.target.value)}
            value={selectedTab}
          >
            <Radio value={TAB_TYPE.CHANNEL}>{t("filter.channel")}</Radio>
            <Radio className="pl-2" value={TAB_TYPE.POST}>
              {t("filter.post")}
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <div className={`bg-white rounded-b-lg flex items-start w-100 h-100`}>
        <div
          className={`py-4 w-[120px] h-100 mr-[12px]`}
          style={{
            borderRight: "1px solid #D9D9D9",
          }}
        >
          {selectedTab === TAB_TYPE.CHANNEL ? (
            <>
              <div
                onClick={() => {
                  onChangeChannel(CHANNEL_TYPE.CHANNEL);
                }}
                className={`${
                  channelType === CHANNEL_TYPE.CHANNEL
                    ? "bg-gray-200 text-black"
                    : ""
                } text-black text-center cursor-pointer p-2`}
              >
                {t("filter.channel")}
              </div>
              <div
                className={`${
                  channelType === CHANNEL_TYPE.GROUP
                    ? "bg-gray-200 text-black"
                    : ""
                } text-black text-center cursor-pointer p-2`}
                onClick={() => {
                  onChangeChannel(CHANNEL_TYPE.GROUP);
                }}
              >
                {t("filter.group-channel")}
              </div>
            </>
          ) : (
            <>
             <div
                className={`bg-white text-black text-center cursor-pointer p-2`}
              >
                {t("filter.post")}
              </div>
              <div
                className={`bg-white text-black text-center cursor-pointer p-2`}
              >
                {t("filter.label_content")}
              </div>
              <div
                className={`bg-gray-200 text-black text-center cursor-pointer p-2`}
              >
                {t("filter.enter_post_id")}
              </div>
            </>
          )}
        </div>
        <Form.Item
          name="channel_ids"
          rules={[
            {
              required: selectedTab === TAB_TYPE.CHANNEL ? true : false,
              message: t("campaign.require_channel"),
            },
          ]}
          className="w-[80%] py-4"
        >
          {selectedTab === TAB_TYPE.CHANNEL ? (
            <>
              {loadingOptions ? (
                <div style={{ textAlign: "center", padding: "10px 0" }}>
                  <Spin tip={"Loading..."} />
                </div>
              ) : (
                <Select
                  mode="multiple"
                  placeholder={
                    loadingOptions ? "Loading..." : t("campaign.choose_channel")
                  }
                  value={
                    conditionTypeChannel() ? selectedItems : selectedGroupItems
                  }
                  onChange={onChangeChannelIds}
                  style={{ width: "100%", flex: "1" }}
                  options={loadingOptions ? [] : options}
                />
              )}
            </>
          ) : (
            <>
              <Form.Item
                name="post_ids"
                rules={[
                  {
                    required: selectedTab === TAB_TYPE.POST ? true : false,
                    message: t("campaign.require_post_id"),
                  },
                ]}
                className="w-[80%]"
              >
                <Input.TextArea
                  placeholder={t("filter.enter_post_id_placeholder")}
                  rows={4}
                  style={{ width: "100%", height: "60px", flex: "1" }}
                  onChange={onChangePostIds}
                  defaultValue={selectedPostIds.join(",")}
                  value={selectedPostIds.join(",")}
                />
              </Form.Item>
            </>
          )}
        </Form.Item>
      </div>
    </div>
  );
};
