import {
  Button,
  Card,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import IconSearch from "../../../assets/svg/IconSearch";
import { useEffect, useState } from "react";
import CreateBot from "./CreateBot";
import IconAddBot from "../../../assets/svg/IconAddBot";
import {
  deleteAutomaticBot,
  getListAutomaticBot,
} from "../../../api/automtic-script";
import { getUserInfor } from "../../../helper/common";
import {
  IChannelInfo,
  IListAutoScriptResponse,
  IListAutoScriptResponseData,
} from "../../../dto/automatic-script/response/response";
import { IconPlatform } from "../../shared/IconPlatform";
import { PLATFORM } from "../../../utils/constants/platform";
import { useTranslation } from "react-i18next";
import IconFilter from "../../../assets/svg/IconFilter";
import { ItemHeader } from "./ItemHeader";
import { getListGroupChannels, loadListChannel } from "../../../api/channel";
import AutoHide from "../../../assets/svg/AutomationBot/AutoHide";
import AutoLike from "../../../assets/svg/AutomationBot/AutoLike";
import AutoReply from "../../../assets/svg/AutomationBot/AutoReply";
import AutoSendMessage from "../../../assets/svg/AutomationBot/AutoSendMessage";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

export enum ActionType {
  OPEN_LIST = 0,
  OPEN_CREATE = 1,
  OPEN_EDIT = 2,
}

enum CHANNEL_TYPE {
  CHANNEL = 1,
  GROUP = 2,
}

export default function CampaignBot() {
  const userInfo = getUserInfor();
  const isMobile = useIsMobile();
  const [textValue, setTextValue] = useState<string>("");
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      fetchData({ pageFilter: 1, search: textValue });
    }
  };

  const { t } = useTranslation();
  const [isOpenCreateOrEdit, setIsOpenCreateOrEdit] = useState<ActionType>(
    ActionType.OPEN_LIST
  );

  const [page, setPage] = useState<number>(1);

  const [itemSelectedToEdit, setItemSelectedToEdit] = useState<
    IListAutoScriptResponseData | undefined
  >();

  const [itemSelectedToDelete, setItemSelectedToDelete] = useState<
    IListAutoScriptResponseData | undefined
  >();

  const handlePageChange = (page: number, pageSize: number) => {
    setPage(page);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [listAutoBot, setListAutoBot] = useState<IListAutoScriptResponse>(
    {} as IListAutoScriptResponse
  );

  const handleClickEdit = (_id: string) => {
    setIsOpenCreateOrEdit(ActionType.OPEN_EDIT);
    const itemSelected = listAutoBot.data.find(
      (item: IListAutoScriptResponseData) => item._id === _id
    );
    setItemSelectedToEdit(itemSelected);
  };

  const [showFilterChannel, setShowFilterChannel] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchData = async ({
    pageFilter,
    search,
    notFilterChannel,
  }: {
    pageFilter?: number;
    search?: string;
    notFilterChannel?: boolean;
  }) => {
    let channelFilter = {};
    if (notFilterChannel) {
      setChannelFiltered([]);
    } else {
      if (channelType === CHANNEL_TYPE.CHANNEL) {
        channelFilter = {
          channel_ids: channelFiltered.length ? channelFiltered : undefined,
        };
      } else if (channelType === CHANNEL_TYPE.GROUP) {
        channelFilter = {
          group_ids: channelFiltered.length ? channelFiltered : undefined,
        };
      }
    }

    setIsLoading(true);
    const data = await getListAutomaticBot(
      Object.assign(
        {
          projectId: userInfo.last_project_active,
          page: pageFilter ? 1 : page,
          search,
        },
        channelFilter
      )
    );
    setListAutoBot(data.data);
    setIsLoading(false);
  };

  const onClickDeleteBot = async (_id: string) => {
    const itemSelected = listAutoBot.data.find(
      (item: IListAutoScriptResponseData) => item._id === _id
    );
    setItemSelectedToDelete(itemSelected);
    setShowDeleteModal(true);
  };

  const handleDeleteBot = async (id: string) => {
    const data = await deleteAutomaticBot({
      project_id: userInfo.last_project_active,
      automatic_script_id: id,
    });
    if (data.data) {
      message.success(t("campaign.delete_success"));
    }

    fetchData({});
  };

  useEffect(() => {
    fetchData({});
  }, [page]);

  const [channelFiltered, setChannelFiltered] = useState<string[]>([]);

  const [options, setOptions] = useState<any[]>([]);

  const [channelType, setChannelType] = useState<CHANNEL_TYPE>(
    CHANNEL_TYPE.CHANNEL
  );

  const onChange = (e: RadioChangeEvent) => {
    setChannelType(e.target.value);
  };

  const getOptionChannel = async () => {
    let data = [];
    if (channelType === CHANNEL_TYPE.CHANNEL) {
      data = await loadListChannel(userInfo.last_project_active, {
        status: 1,
        is_error: 0,
      });
    } else {
      data = await getListGroupChannels(userInfo.last_project_active, {});
    }

    setOptions(data.data.channel || data.data);
  };

  const ModalDeleteBot = () => (
    <>
      <Modal
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        centered={true}
        footer={null}
      >
        {/* <ModalBody className="py-3 px-5"> */}
        <div className="mt-2 text-center">
          <div>
            <ExclamationCircleOutlined
              style={{ color: "rgb(255 133 0 / 66%)", fontSize: "64px" }}
            />
          </div>
          {/* <i className="ri-delete-bin-line display-5 text-danger"></i> */}
          <div className="mt-4 pt-2 ">
            <Typography className="font-medium leading-8 text-[24px] text-base">
              {t("campaign.confirm_delete_title")}{" "}
              <b>{itemSelectedToDelete?.name}</b> ?
            </Typography>
            <p className="mx-4 mt-4">{t("campaign.not_revert")}</p>
          </div>
        </div>
        <div className="flex gap-2 justify-center mt-6 mb-2">
          <Button
            className="bg-[#bfbfbf] font-semibold flex text-[#FFFFFF]"
            onClick={() => setShowDeleteModal(false)}
            key="back"
          >
            <p className="pt-[1px]">{t("remind.close")}</p>
          </Button>

          <Button
            onClick={async () => {
              await handleDeleteBot(itemSelectedToDelete?._id ?? "");
              setShowDeleteModal(false);
            }}
            key="btn_save"
            // type="primary"
            className="bg-[#ff4d4f] font-semibold text-[#FFFFFF]"
          >
            <p className="pt-[1px]"> {t("campaign.confirm_delete")}</p>
          </Button>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );

  useEffect(() => {
    if (itemSelectedToEdit) {
      const channelIdSelected = itemSelectedToEdit.channel.ids;
      setChannelFiltered(channelIdSelected);
    }
    getOptionChannel();
  }, [channelType]);

  const columns: any = [
    { title: t("campaign.bot"), dataIndex: "name", key: "name" },
    {
      title: t("campaign.status"),
      dataIndex: "status",
      key: "status",
      render: (status: boolean) => {
        return (
          <>
            {status ? (
              <span className="text-[#10B981]">{t("campaign.active")}</span>
            ) : (
              <span className="text-[#9CA3AF]">{t("campaign.in_active")}</span>
            )}
          </>
        );
      },
    },
    {
      title: t("campaign.object"),
      dataIndex: "auto_setting",
      key: "auto_setting",
      render: (_: any, record: IListAutoScriptResponseData) => (
        <div id="auto_setting_icon" className="flex items-center">
          {record.is_auto_like_comment === 1 && (
            <Tooltip
              title={t("campaign.auto_like_comment")}
              key={"auto_like_comment"}
            >
              <div className="cursor-pointer">
                <AutoLike />
              </div>
            </Tooltip>
          )}
          {record.is_auto_send_message_after_reply_comment === 1 && (
            <Tooltip
              title={t("campaign.auto_send_message_comment")}
              key={"auto_send_message_comment"}
            >
              <div className="ml-2 cursor-pointer">
                <AutoSendMessage />
              </div>
            </Tooltip>
          )}
          {record.is_auto_reply_comment === 1 && (
            <Tooltip
              title={t("campaign.auto_rep_comment")}
              key={"auto_rep_comment"}
            >
              <div className="ml-2 cursor-pointer">
                <AutoReply />
              </div>
            </Tooltip>
          )}

          {record.is_auto_reply_comment === 1 && (
            <Tooltip
              title={t("campaign.auto_hide_comment")}
              key={"auto_hide_comment"}
            >
              <div className="ml-2 cursor-pointer">
                {" "}
                <AutoHide />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: t("campaign.platform"),
      dataIndex: "channel_info",
      key: "channel_info",
      render: (item: IChannelInfo) => {
        const platforms = item?.full_info
          ?.map((c: any) => c.platform)
          .filter(
            (value: number, index: number, array: any[]) =>
              array.indexOf(value) === index
          );

        return platforms.map(
          (
            p:
              | PLATFORM.PLATFORM_FACEBOOK
              | PLATFORM.PLATFORM_INSTAGRAM
              | PLATFORM.PLATFORM_TIKTOK
              | PLATFORM.PLATFORM_ZALO_OA
          ) => <IconPlatform platform={p} />
        );
      },
    },
    {
      title: t("campaign.action"),
      key: "_id",
      dataIndex: "_id",
      render: (_id: string) => (
        <Space size="middle">
          <a onClick={() => handleClickEdit(_id)}>{t("campaign.edit")}</a>
          <a onClick={() => onClickDeleteBot(_id)}>{t("campaign.delete")}</a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-center">
        <div className={"w-[90%] min-w-[90%]"}>
          {isOpenCreateOrEdit !== ActionType.OPEN_LIST ? (
            <CreateBot
              isOpenCreateOrEdit={isOpenCreateOrEdit}
              setIsOpenCreateOrEdit={setIsOpenCreateOrEdit}
              itemSelectedToEdit={
                isOpenCreateOrEdit === ActionType.OPEN_EDIT
                  ? itemSelectedToEdit
                  : undefined
              }
              setListAutoBot={setListAutoBot}
            />
          ) : (
            <>
              {" "}
              <Card title={!isMobile ? t("campaign.list_bot") : null}>
                <div
                  className="flex gap-2"
                  style={
                    isMobile
                      ? { flexDirection: "column" }
                      : { justifyContent: "space-between" }
                  }
                >
                  <div className={`relative flex-4 ${!isMobile ? 'w-[40%]' : 'w-100'}`}>
                    <input
                      type="search"
                      id="search"
                      value={textValue}
                      onChange={(e) => setTextValue(e.target.value)}
                      onKeyDown={handleKeyDown}
                      className="block w-full h-9 p-4 text-sm text-gray-900 border border-gray-300 rounded-md bg-white"
                      placeholder={t("campaign.search_placeholder")}
                    />
                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                      <IconSearch />
                    </div>
                  </div>

                  <div className="flex">
                    <div>
                      <Button
                        onClick={() => {
                          if (showFilterChannel && channelFiltered.length) {
                            fetchData({ notFilterChannel: true });
                          }
                          setShowFilterChannel(!showFilterChannel);
                        }}
                      >
                        <div className="flex items-center">
                          <IconFilter />
                          <div className="pl-2">
                            {" "}
                            {showFilterChannel
                              ? t("campaign.clear_filter")
                              : t("campaign.filter")}
                          </div>
                        </div>
                      </Button>
                    </div>
                    <div className="pl-3">
                      <Button
                        onClick={() =>
                          setIsOpenCreateOrEdit(ActionType.OPEN_CREATE)
                        }
                      >
                        <div className="flex items-center">
                          <IconAddBot />
                          <div className="pl-2">{t("campaign.create")}</div>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>

                {showFilterChannel && (
                  <>
                    <div
                      className={`flex ${isMobile ? 'flex-col items-start' : 'items-center'} p-4 mt-8 bg-gray-100  gap-2 rounded-t-lg`}
                    >
                      <ItemHeader title={t("campaign.select_channel_type")} />
                      <div className={`${isMobile ? 'items-start' : 'pl-8 items-center'}`}>
                        <Radio.Group onChange={onChange} value={channelType}>
                          <Radio value={CHANNEL_TYPE.CHANNEL}>
                            {t("filter.channel")}
                          </Radio>
                          <Radio className="pl-2" value={CHANNEL_TYPE.GROUP}>
                            {t("filter.group-channel")}
                          </Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className={`flex ${isMobile ? 'flex-col text-left py-4' : 'p-4'} bg-white rounded-b-lg `}>
                      <div className={`flex ${isMobile ? 'w-100 mb-2' : "w-[40%]"}`}>
                        <Select
                          mode="multiple"
                          placeholder={t("campaign.choose_channel")}
                          value={channelFiltered}
                          onChange={setChannelFiltered}
                          style={{ width: "100%" }}
                          options={options?.map((item) => ({
                            value: item?._id,
                            label: item?.name,
                          }))}
                        />
                      </div>
                      <div className={`${isMobile ? 'w-100' : 'pl-8'}`}>
                        <Button className="" onClick={() => fetchData({})}>
                          <div className="flex items-center">
                            <IconFilter />
                            <div className="pl-2">
                              {t("campaign.filter_channel")}
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Card>
              <div className="mt-8">
                <Table
                  loading={isLoading}
                  columns={columns}
                  dataSource={listAutoBot.data || []}
                  rowKey={({ _id }) => _id}
                  scroll={isMobile ? { x: 400 } : {}}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 10,
                    total: listAutoBot.total,
                  }}
                />
              </div>
              <ModalDeleteBot />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
