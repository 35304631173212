import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

export const AutoNotes = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <div className={`flex flex-col gap-4 ${isMobile ? "text-start" : ""}`}>
      <Typography className="text-base font-[600]">
        {t("campaign.notes.warning")}
      </Typography>
      <div>
        <Typography className="font-[600] text-base">
          {t("campaign.notes.warning_1")}{" "}
          <span className="text-[#D53784]">
            {" "}
            {t("campaign.notes.warning_1_2")}
          </span>{" "}
          {t("campaign.notes.warning_1_3")}
          <span className="text-[#D53784]">
            {t("campaign.notes.warning_1_4")}
          </span>
        </Typography>
      </div>
      <div>
        {" "}
        <span className="text-[#D53784]">
          {t("campaign.notes.warning_1_2")}
        </span>{" "}
        {t("campaign.notes.warning_2")}
      </div>
      <div>
        <span className="text-[#D53784]">
          {t("campaign.notes.warning_2_2")}
        </span>{" "}
        {t("campaign.notes.warning_2_3")}
      </div>
    </div>
  );
};
