import { Radio, Button, Select, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  resetConversationByFilter,
  setFilters,
} from "../../../reducers/conversationSlice";
import {
  CONVERSATION_FILTER_COMMENT,
  CONVERSATION_FILTER_MESSAGE,
} from "../../../utils/constants/conversation";
import moment from "moment";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

interface IStatusFilter {
  comment: boolean;
  message: boolean;
  phone: boolean;
  no_phone: boolean;
  not_reply: boolean;
  channels: boolean;
}

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export default function DrawerFilter({ isOpenFilter, toggleFilter }: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { filters } = useSelector((state: RootState) => state.conversation);
  const tags = useSelector((state: RootState) => state.conversationScript.tags);
  const channels = useSelector((state: RootState) => state.channels.channels);

  const [selectedChannelId, setSelectedChannelsId] = useState<string[]>([]);
  const [tagOptions, setTagOptions] = useState<any>([]);
  const [tagValue, setTagValue] = useState<any>([]);
  const [statusFilters, setStatusFilter] = useState<IStatusFilter>({
    comment: false,
    message: false,
    phone: false,
    no_phone: false,
    not_reply: false,
    channels: false,
  });
  const [dateFilter, setDateFilter] = useState<{ from: string; to: string }>({
    from: "",
    to: "",
  });

  useEffect(() => {
    if (filters.channel) {
      setSelectedChannelsId(filters.channel);
    }
  }, []);

  useEffect(() => {
    if (tags && tags.length > 0) {
      const tagOptions = tags.map((tag) => ({
        label: tag.name,
        value: tag._id,
      }));
      setTagOptions(tagOptions);
    }
  }, [tags]);

  let options =
    channels?.map((item) => ({
      value: item?._id,
      label: item?.name,
    })) || [];

  const handleChangeTag = (value: string[]) => {
    setTagValue(value);
  };

  const handleChangeChannel = (value: string[]) => {
    setSelectedChannelsId(value);
  };

  const handleClickFilterItem = (
    field: "phone" | "no_phone" | "comment" | "message"
  ) => {
    setStatusFilter((prev) => {
      let newStatus = { ...prev };

      if (field === "comment") {
        newStatus.message = false;
        newStatus.comment = !newStatus.comment;
      } else if (field === "message") {
        newStatus.comment = false;
        newStatus.message = !newStatus.message;
      } else if (field === "phone") {
        newStatus.no_phone = false;
        newStatus.phone = !newStatus.phone;
      } else if (field === "no_phone") {
        newStatus.phone = false;
        newStatus.no_phone = !newStatus.no_phone;
      }

      return newStatus;
    });
  };

  const handleChangeDate = (date: RangeValue, dateString: [string, string]) => {
    let newDate = { ...dateFilter };

    if (dateString[0]) {
      newDate.from = moment(dateString[0], "YYYY-MM-DD HH").format(
        "YYYY-MM-DD"
      );
    }
    if (dateString[1]) {
      newDate.to = moment(dateString[1], "YYYY-MM-DD HH").format("YYYY-MM-DD");
    }
    setDateFilter(newDate);
  };

  const handleApplyFilters = () => {
    dispatch(resetConversationByFilter());

    let newFilterFetch = { ...filters };

    if (selectedChannelId.length > 0) {
      newFilterFetch.channel = selectedChannelId;
    }

    if (tagValue.length > 0) {
      newFilterFetch.tag = tagValue;
    }

    if (!!dateFilter.from && !!dateFilter.to) {
      newFilterFetch.range_time = dateFilter;
    }

    if (statusFilters.comment) {
      newFilterFetch.type = CONVERSATION_FILTER_COMMENT;
    } else if (statusFilters.message) {
      newFilterFetch.type = CONVERSATION_FILTER_MESSAGE;
    }

    if (statusFilters.phone) {
      newFilterFetch.has_phone = true;
    } else if (statusFilters.no_phone) {
      newFilterFetch.has_phone = 0;
    }

    dispatch(setFilters(newFilterFetch));
    toggleFilter();
  };

  const handleResetFilters = () => {
    setSelectedChannelsId([]);
    setTagValue([]);
    setDateFilter({ from: "", to: "" });
    setStatusFilter({
      comment: false,
      message: false,
      phone: false,
      no_phone: false,
      not_reply: false,
      channels: false,
    });

    dispatch(resetConversationByFilter());
    dispatch(setFilters({}));
    toggleFilter();
  };

  return (
    <>
      <div
        className={`fixed top-[55px] right-0 h-full w-3/4 bg-white shadow-lg transform ${
          isOpenFilter ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out z-[10001]`}
      >
        <div className="pt-4 px-4 flex justify-start items-center">
          <h2 className="text-[16px] font-[600] text-gray-400">Filter</h2>
        </div>

        <div
          className="flex flex-col"
          style={{ height: "calc(100vh - 110px)" }}
        >
          <div className="flex flex-col">
            <div className="px-4 py-2 text-start">
              <label className="text-[14px] font-[500]">{t('filter.bytag')}</label>
              <Select
                className="w-full mt-2 z-[10003]"
                mode="multiple"
                placeholder="Chọn nhãn"
                options={tagOptions}
                onChange={handleChangeTag}
                value={tagValue}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </div>

            <div className="px-4 py-2 text-start">
              <label className="text-[14px] font-[500]">Loại hội thoại</label>
              <Radio.Group
                className="mt-1 flex gap-4"
                value={
                  statusFilters.comment
                    ? "comment"
                    : statusFilters.message
                    ? "message"
                    : null
                }
              >
                <Radio
                  value="message"
                  onClick={() => handleClickFilterItem("message")}
                >
                  Message
                </Radio>
                <Radio
                  value="comment"
                  onClick={() => handleClickFilterItem("comment")}
                >
                  Comment
                </Radio>
              </Radio.Group>
            </div>

            <div className="px-4 py-2 text-start">
              <label className="text-[14px] font-[500]">Liên hệ</label>
              <Radio.Group
                className="mt-1 flex gap-4"
                value={
                  statusFilters.phone
                    ? "phone"
                    : statusFilters.no_phone
                    ? "no_phone"
                    : null
                }
              >
                <Radio
                  value="phone"
                  onClick={() => handleClickFilterItem("phone")}
                >
                  {t('filter.hasphone')}
                </Radio>
                <Radio
                  value="no_phone"
                  onClick={() => handleClickFilterItem("no_phone")}
                >
                  {t('filter.nophone')}
                </Radio>
              </Radio.Group>
            </div>

            <div className="px-4 py-2 text-start">
              <label className="text-[14px] font-[500]">{t('filter.by-time')}</label>
              <DatePicker.RangePicker
                showTime={{ format: "HH" }}
                format="YYYY-MM-DD HH"
                onCalendarChange={handleChangeDate}
                className="mt-2 text-[10px] py-1"
                popupClassName="z-[10010]"
                size="small"
              />
            </div>

            <div className="px-4 py-2 text-start">
              <label className="text-[14px] font-[500]">{t('filter.channel')}</label>
              <Select
                className="w-full mt-2 z-[10003]"
                placeholder={t("campaign.choose_channel")}
                allowClear
                mode="multiple"
                options={options}
                onChange={handleChangeChannel}
                value={selectedChannelId}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </div>
          </div>

          <div className="px-4 pt-4 mt-4 flex justify-between border-t gap-2">
            <Button className="border flex-1" onClick={handleResetFilters}>
              Remove Filter
            </Button>
            <Button
              type="primary"
              className="flex-1"
              onClick={handleApplyFilters}
            >
              Filter
            </Button>
          </div>
        </div>
      </div>

      {isOpenFilter && (
        <div
          className="fixed top-[55px] inset-0 bg-black bg-opacity-50 z-[10000]"
          onClick={toggleFilter}
        ></div>
      )}
    </>
  );
}
