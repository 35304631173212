import { Outlet, useLocation } from "react-router-dom";
import MobileHeader from "./MobileHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import DetailConversationHeader from "./DetailConversationHeader";
import { useState } from "react";

export default function MobileLayout() {
  const { activeConversationId } = useSelector(
    (state: RootState) => state.conversation
  );
  const location = useLocation();
  const homePage = location.pathname === "/";

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenBar, setIsOpenBar] = useState(false);

  const [isOpenTagSide, setIsOpenTagSide] = useState(false);
  const [isOpenNoteSide, setIsOpenNoteSide] = useState(false);
  const [isOpenMenuSide, setIsOpenMenuSide] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleFilter = () => {
    setIsOpenFilter(!isOpenFilter);
    setIsOpenBar(false);
  };

  const toggleBar = () => {
    setIsOpenBar(!isOpenBar);
    setIsOpenFilter(false);
  };

  const toggleCloseAllMenu = () => {
    setIsOpenFilter(false);
    setIsOpenBar(false);
  };

  const toggleTagSide = () => {
    setIsOpenNoteSide(false);
    setIsOpenMenuSide(false);
    setIsOpenTagSide(!isOpenTagSide);
  };

  const toggleNoteSide = () => {
    setIsOpenTagSide(false);
    setIsOpenMenuSide(false);
    setIsOpenNoteSide(!isOpenNoteSide);
  };

  const toggleMenuSide = () => {
    setIsOpenTagSide(false);
    setIsOpenNoteSide(false);
    setIsOpenMenuSide(!isOpenMenuSide);
  };

  const toggleCloseAll = () => {
    setIsOpenTagSide(false);
    setIsOpenNoteSide(false);
    setIsOpenMenuSide(false);
    setIsScrolled(false);
  };

  const outletContext = {
    isOpenMenuSide,
    toggleMenuSide,
    isOpenTagSide,
    toggleTagSide,
    isOpenNoteSide,
    toggleNoteSide,
    isScrolled,
    setIsScrolled,
  };

  return (
    <>
      <div>
        {activeConversationId ? (
          <DetailConversationHeader
            toggleTagSide={toggleTagSide}
            toggleNoteSide={toggleNoteSide}
            toggleMenuSide={toggleMenuSide}
            toggleCloseAll={toggleCloseAll}
          />
        ) : (
          <MobileHeader
            isOpenFilter={isOpenFilter}
            toggleFilter={toggleFilter}
            isOpenBar={isOpenBar}
            toggleBar={toggleBar}
            toggleCloseAllMenu={toggleCloseAllMenu}
            isScrolled={isScrolled}
          />
        )}
      </div>
      <div
        className={`${
          homePage && !activeConversationId && isScrolled
            ? "translate-y-0 transition-transform duration-300"
            : homePage && !activeConversationId
            ? "translate-y-[55px] transition-transform duration-300"
            : "mt-[55px]"
        }`}
      >
        <Outlet context={outletContext} />
      </div>
    </>
  );
}
