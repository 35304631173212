import { Typography } from "antd";
import { useEffect, useState } from "react";
import { Note } from "./note";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Tags from "./topic";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../utils/hooks/useIsMobile";

export default function ConversationTag() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [loadingTopic, setLoadingTopic] = useState(true);

  const tags = useSelector((state: RootState) => state.conversationScript.tags);
  useEffect(() => {
    if (tags) {
      setLoadingTopic(false);
    }
  }, [tags]);

  return (
    <div
      className={`${
        isMobile
          ? "bg-gray-100 py-[55px] min-h-[100vh]"
          : "m-auto 2xl:max-w-[1200px] max-w-[1100px]"
      }`}
    >
      {!isMobile && (
        <Typography className={`text-2xl`}>{t("tag.header")}</Typography>
      )}
      <div className={`flex gap-4 ${isMobile ? "flex-col px-4" : "mt-6"}`}>
        <div className={`${isMobile ? "w-100" : "w-[542px]"}`}>
          <Tags loadingTopic={loadingTopic} />
        </div>
        <div className={`flex-1`}>
          <Note />
        </div>
      </div>
    </div>
  );
}
