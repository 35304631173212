import { Form, Input } from "antd";
import { useState } from "react";
import {
  ICreateAutomaticScript,
  IUpdateAutomaticScrip,
} from "../../../dto/automatic-script/request/request";
import { useTranslation } from "react-i18next";

export default function ItemCampaignName({
  itemSelectedToEdit,
  setData,
}: {
  itemSelectedToEdit?: ICreateAutomaticScript | IUpdateAutomaticScrip;
  setData: any;
}) {
  const { t } = useTranslation();

  const [textValue, setTextValue] = useState<string>(
    itemSelectedToEdit?.name || ""
  );

  const handleTextChange = (value: any) => {
    setTextValue(value);
    setData((pre: any) => {
      return { ...pre, name: value };
    });
  };
  return (
    <div className={`p-4 bg-white rounded-lg border-[1px]`}>
      <div className="flex pb-0 justify-start gap-20 items-center">
        <div className="font-bold">{t("campaign.bot_name")}</div>
        <div className={`relative flex-4 pt-5 w-[60%] `}>
          <Form.Item
            // className="m-0"
            name="name"
            rules={[{ required: true, message: t("campaign.require_name") }]}
          >
            <Input
              value={textValue}
              onChange={(e) => handleTextChange(e.target.value)}
              className="block w-full h-9 p-4 text-sm text-gray-900 border border-gray-300 rounded-md bg-white"
              placeholder={t("campaign.placeholder_name")}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
}
