import ItemContent from "../../shared/ItemContent";
import { IconQuestion } from "../../../assets/svg/ConversationScript/IconConersationScript";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

export function Note() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <div className={`mt-6 ${isMobile ? "border-[1px] rounded-lg" : ""}`}>
      <ItemContent
        childContent={
          <div>
            <Typography className={`font-semibold text-sm`}>
              {t("script-setting.group-sample")}{" "}
              <Typography className={`inline font-normal`}>
                {t("script-setting.group-sample-desc")}
              </Typography>
            </Typography>
          </div>
        }
        iconTitle={<IconQuestion />}
        title={t("script-setting.note")}
      />
    </div>
  );
}
