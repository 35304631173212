import TextArea from "antd/es/input/TextArea";
import { ItemHeader } from "./ItemHeader";
import { AutoNotes } from "./AutoNotes";
import { Dispatch, SetStateAction, useState } from "react";
import {
  ICreateAutomaticScript,
  IUpdateAutomaticScrip,
} from "../../../dto/automatic-script/request/request";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

export const CommentContent = ({
  itemSelectedToEdit,
  setData,
}: {
  itemSelectedToEdit?: ICreateAutomaticScript | IUpdateAutomaticScrip;
  setData: Dispatch<
    SetStateAction<ICreateAutomaticScript | IUpdateAutomaticScrip | undefined>
  >;
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [textValue, setTextValue] = useState(
    itemSelectedToEdit?.comment?.text || ""
  );

  const handleTextChange = (value: string) => {
    setData((pre: any) => ({
      ...pre,
      comment: { text: value },
    }));
    setTextValue(value);
  };

  return (
    <div className={`${isMobile ? "border-[1px] rounded-lg" : "w-[49%]"}`}>
      <div className={`flex p-4 bg-gray-100 items-center gap-2 rounded-t-lg`}>
        <ItemHeader title={t("campaign.enter_content")} />
      </div>
      <div
        className={`p-4 flex gap-y-5 bg-white items-center rounded-b-lg flex-wrap`}
      >
        <TextArea
          required 
          placeholder={t("campaign.enter_content_here")}
          value={textValue}
          onChange={(e: any) => handleTextChange(e.target.value)}
          rows={4}
        />
        <AutoNotes />
      </div>
    </div>
  );
};
