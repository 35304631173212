import axios from "axios"
import Cookies from "js-cookie"
import { getDomainApp, getDomainForCookie, getDomainLogin, getRedirectUrl } from "../helper/common";
export default class Auth {
    static saveToken(token: any) {
        window.localStorage.setItem('accessToken', token);
        const domain = getDomainForCookie();
        Cookies.set('accessToken', token, { domain, expires: 1 });
    }

    static removeToken() {
        window.localStorage.removeItem('accessToken');
    }

    static async refreshToken() {
        try {
            let accessToken = Cookies.get('accessToken');
            if (accessToken == null || accessToken == '') {
                accessToken = window.localStorage.getItem("accessToken") ?? '';
            }

            const refreshRes = await axios.post(process.env.REACT_APP_URL_API + "api/v1/refresh", {}, {
                headers: { 'Authorization': 'Bearer ' + accessToken },
            });
            const newToken = refreshRes?.data?.data?.access_token;
            if (!newToken) {
                return false;
            }
            this.saveToken(newToken);
            return true;
        }
        catch (e) {
            this.removeToken();
            const domain = getDomainLogin();
            const redirectUrl = getRedirectUrl();
            window.location.href = domain + '/auth-login?redirectUrl=' + redirectUrl;
        }
    }
}
