import { useDispatch, useSelector } from "react-redux";
import {
  resetConversationByFilter,
  setConversationList,
  setSearchText,
} from "../../reducers/conversationSlice";
import { IConversationItem } from "../../dto";
import { getConversations } from "../../api/conversation";
import { message } from "antd";
import { RootState } from "../../store";
import { getUserInfor } from "../../helper/common";
import { useEffect, useState } from "react";
import DrawerHamburgerBar from "../../components/mobile/drawer/DrawerHamburgerBar";
import DrawerFilter from "../../components/mobile/drawer/DrawerFilter";
import { useLocation, useNavigate } from "react-router-dom";

export default function MobileHeader({
  isOpenFilter,
  toggleFilter,
  isOpenBar,
  toggleBar,
  toggleCloseAllMenu,
  isScrolled,
}: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const { conversationPage, filters, searchText } = useSelector(
    (state: RootState) => state.conversation
  );
  const userInfor = getUserInfor();
  const [isSearching, setIsSearching] = useState(false);
  const [textValue, setTextValue] = useState<string>(searchText);

  useEffect(() => {
    setTextValue(searchText);
  }, [searchText]);

  const toggleSearch = () => {
    setIsSearching(!isSearching);
    toggleCloseAllMenu();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    dispatch(resetConversationByFilter());
    dispatch(setSearchText(textValue));
    setIsSearching(false);
  };

  const fetchConversation = async () => {
    try {
      const conversations: IConversationItem[] = await getConversations({
        project_id: userInfor?.last_project_active || "",
        page: conversationPage,
        filter: filters,
        ...(searchText && { search: searchText }),
      });
      dispatch(setConversationList(conversations));
    } catch (e) {
      console.error(e);
      message.error(
        "Đã có lỗi xảy ra khi tải các đoạn hội thoại. Vui lòng thử lại sau."
      );
    }
  };

  return (
    <>
      <div
        className={`${
          isScrolled
            ? "opacity-0 translate-y-[-10px]"
            : "opacity-100 translate-y-0"
        } transition-all duration-300 bg-header h-[55px] flex items-center justify-between fixed top-0 w-full px-4 text-white z-[10000]`}
      >
        <img
          width={150}
          alt={""}
          src={"/chat/logo-new.png"}
          onClick={() => {
            dispatch(resetConversationByFilter());
            fetchConversation();
            toggleCloseAllMenu();
            navigate("/");
          }}
        />
        <div className="flex gap-4 h-[55px] items-center">
          {isHomePage && (
            <>
              <div onClick={toggleSearch}>
                {isSearching ? (
                  <i className="text-[18px] ri-close-line"></i>
                ) : (
                  <i className="text-[18px] ri-search-line"></i>
                )}
              </div>
              <div
                onClick={() => {
                  toggleFilter();
                  setIsSearching(false);
                }}
              >
                <i className="text-[18px] ri-filter-2-line"></i>
              </div>
            </>
          )}
          <div
            onClick={() => {
              toggleBar();
              setIsSearching(false);
            }}
          >
            <i className="text-[18px] ri-menu-fill"></i>
          </div>
        </div>

        {isSearching && (
          <div className="flex items-center w-full absolute left-0 top-[55px] h-[45px] bg-white px-4 gap-2 shadow-xl">
            <input
              type="text"
              className="w-full px-2 h-[32px] rounded-md border focus:outline-none text-black text-[12px]"
              placeholder="Search"
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button
              className="text-blue-500 text-[14px]"
              onClick={handleSearch}
            >
              <i className="text-[18px] ri-search-line"></i>
            </button>
          </div>
        )}
      </div>

      <DrawerFilter isOpenFilter={isOpenFilter} toggleFilter={toggleFilter} />

      <DrawerHamburgerBar isOpenBar={isOpenBar} toggleBar={toggleBar} />
    </>
  );
}
