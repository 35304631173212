import ItemContent from "../../shared/ItemContent";
import { IconPlus, IconTopic } from "../../../assets/svg/ConversationScript/IconConersationScript";
import { message, Spin } from "antd";
import ItemTopic from "./ItemTopic";
import { lazy, Suspense, useState } from "react";
import { delTopic } from "../../../api/conversationScript";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setReplyTopics } from "../../../reducers/conversationScriptSlice";
import { IReplyTopicItem } from "../../../dto/reply-topic";
import { useTranslation } from "react-i18next";
import { getUserInfor } from "../../../helper/common";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

export default function Topic({ loadingTopic }: {
  loadingTopic: boolean
}) {
  const dispatch = useDispatch()
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const [openModalAdd, setOpenModalAdd] = useState(false)
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [currentTopic, setCurrentTopic] = useState({})
  const { replyTopics } = useSelector((state: RootState) => state.conversationScript)
  const userInfor = getUserInfor();
  const generateListTopic = () => {
    if (loadingTopic) {
      return <div className={`flex items-center h-full justify-center`}>
        <Spin size="large">
        </Spin>
      </div>
    } else {
      if (replyTopics === undefined) {
        return <>{t('script-setting.topic-empty')}</>
      } else if (replyTopics.length === 0) {
        return <div className={`flex justify-center text-sm font-semibold`}>{t('script-setting.topic-empty1')}</div>
      } else {
        return <div className={`flex flex-col max-h-[200px] gap-2 overflow-auto`}>
          {
            replyTopics.map((item, key) => {
              return <ItemTopic handleDelTopic={(id: string) => handleDelTopic(id)} item={item} key={key}
                handleEditTopic={() => handleEditTopic(item)}
              />
            })
          }
        </div>
      }
    }
  }

  const handleEditTopic = (item: any) => {
    if (item) {
      setCurrentTopic(item)
    }
    setOpenModalEdit(true);

  }
  const handleCreateTopic = (name: string, color: string, _id: string) => {
    const newListTopic = [...replyTopics || []]
    newListTopic.push({
      name,
      color,
      _id
    })
    dispatch(setReplyTopics(newListTopic))
    setOpenModalAdd(false)
  }

  const handleEditReduxTopic = (name: string, color: string, _id: string) => {
    let newListTopic: IReplyTopicItem[] | undefined;
    if (replyTopics && replyTopics.length > 0) {
      newListTopic = replyTopics.map((topic) => {
        
        if (topic._id === _id) {
          return {
            ...topic,
            name: name,
            color: color,
          }
        } return topic;
      }
      )
    }
    
    dispatch(setReplyTopics(newListTopic))
    setOpenModalEdit(false)
  }

  const handleDelTopic = async (id: string) => {
    try {
      const delTop = await delTopic(userInfor?.last_project_active, id)
      if (delTop) {
        const newListTopic = [...replyTopics || []]
        const findIndexTopicByIdDel = newListTopic.findIndex((item) => item._id === id)
        if (findIndexTopicByIdDel >= 0) {
          newListTopic.splice(findIndexTopicByIdDel, 1)
          dispatch(setReplyTopics(newListTopic))
        }
        message.success(t('alertmess.succes.delete-topic'))
      }
    } catch (e) {
      message.error(t('alertmess.error.delete-topic'))
    }

  }

  return (
    <div className={`${isMobile ? "border-[1px] rounded-lg" : ""}`}>
      <ItemContent
        childContent={generateListTopic()}
        iconTitle={<IconTopic />}
        iconExtra={<div className={`cursor-pointer`} onClick={() => setOpenModalAdd(true)}>
          <IconPlus />
        </div>}
        title={t('script-setting.topic-title')}  
      />
      <Suspense>
        <ModalAddNewTopic
          open={openModalAdd}
          handleClose={() => setOpenModalAdd(false)}
          handleConfirm={(name: string, color: string, _id: string) => handleCreateTopic(name, color, _id)}
          type="add"
          currentTopic={currentTopic}
        />
        <ModalAddNewTopic
          open={openModalEdit}
          handleClose={() => setOpenModalEdit(false)}
          handleConfirm={(name: string, color: string, _id: string) => handleEditReduxTopic(name , color , _id)}
          currentTopic={currentTopic}
          type="edit"
        />
      </Suspense>
    </div>
  )
}

const ModalAddNewTopic = lazy(() => import('../topic/ModalAddNewTopic'))